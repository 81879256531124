














import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const PlansStore = namespace('PlansStore')
import { PlanModel } from '@/core/models/PlanModel'
import PremiumPlan from './PremiumPlan.vue'

@Component({
  name: 'PremiumPlans',
  components: {
    PremiumPlan,
  },
})
export default class PremiumPlans extends Vue {
  @PlansStore.Getter
  public planList!: PlanModel[]
}
