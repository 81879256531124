






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { PlanModel } from '@/core/models/PlanModel'
import { toLocalCurrency } from '@/utils/currencyFormatter'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'PremiumPlan',
  components: { BaseButton, BaseIcon },
})
export default class PremiumPlan extends Vue {
  @Prop({ required: true })
  plan!: PlanModel

  toLocalCurrency = toLocalCurrency
  baseUrl = process.env.VUE_APP_URL
}
